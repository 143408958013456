import { render, staticRenderFns } from "./InsFooter.vue?vue&type=template&id=ef7ff794&scoped=true&"
import script from "./InsFooter.vue?vue&type=script&lang=ts&"
export * from "./InsFooter.vue?vue&type=script&lang=ts&"
import style0 from "./InsFooter.vue?vue&type=style&index=0&id=ef7ff794&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef7ff794",
  null
  
)

export default component.exports