



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class InsFooter extends Vue {
  currentYear: number = 0;

  created () {
    var date = new Date();
    this.currentYear = date.getFullYear();
  }

  mounted () {}
}
